/* SpeedTypingGame.css */

.container {
    max-width: 650px;
    margin: 5px;
    width: calc(100% - 10px);
    padding: 30px;
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    background: #fff;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
}

input.input-field {
    opacity: 0;
    z-index: -999;
    position: absolute;
}

#paragraph {
    word-break: break-all;
    line-height: 1.1;
    text-align: left;
}

.section {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 20px;
    padding-top: 0;
}

.section1 {
    border-bottom: 1px solid #ced4da;
}

span {
    user-select: none;
    cursor: text;
}

span.wrong {
    color: darkred;
    background-color: pink;
}

span.correct {
    color: #0e630e;
    background: #e7fbd3;
}

span.active {
    color: #0a6bf9;
}

.section2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}

.section2 ul {
    padding-inline-start: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
    width: calc(100% - 220px);
}

.section2 ul li {
    list-style-type: none;
    margin: 0 10px;
}

.section2 ul li p {
    margin: 5px;
    font-size: 12px;
}

.section2 ul li span {
    font-size: 18px;
}

button.btn {
    padding: 10px 20px;
    outline: none;
    border: 1px solid #ced4da;
    margin: 5px 2px;
    border-radius: 4px;
    cursor: pointer;
    background: #1d9bf0;
    color: #fff;
    font-size: 16px;
    transition: all 0.5s;
}

button.btn:hover {
    background-color: #1879ba;
}

@media screen and (max-width : 550px) {
    .section2 {
        flex-direction: column;
    }

    .section2 ul {
        width: 100%;
        justify-content: space-between;
    }

    button.btn {
        width: 100%;
        margin-top: 10px;
    }
}