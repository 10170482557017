@tailwind base;
@tailwind components;
@tailwind utilities;

.sidebar {
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    position: fixed;
    height: 100%;
    background: #e5edff;
}

.leftside {
    float: left;
    position: fixed;
    height: 100%;
    background: #fff;
    margin-top: 0;
    z-index: 99;
}

.space-f-s li a {
    font-size: 16px;
    font-weight: bold;
}

.rightside {
    margin-left: 257px;
    background: #f9f9f9;
    height: 100vh;
    padding-left: 15px;
    padding-top: 9px
}

.top-nav-bar {
    z-index: 9;
    height: 64px;
    background: #e5edff;
    width: 100%;
    position: relative;
    box-shadow: 0 0 0 0 rgb(0 0 0 / 0), 0 3px 1px -2px rgb(0 0 0 / 0), 0 1px 2px 0 rgb(0 0 0 / 19%);
    position: fixed;
    top: 0
}

.logo {
    width: 256px;
    float: left;
    height: 64px;
    background: #fff
}

.logoskill {
    width: 62%;
    margin: 14px auto
}

.welcome-dash {
    top: 50%;
    width: 100%;
    vertical-align: middle;
    position: relative
}

.welcome-dash h2 {
    text-align: center;
    font-family: Monospace;
    font-size: 35px;
    text-transform: uppercase
}

.rightsidetop {
    margin-top: 68px;
    margin-right: 15px;
}

.margin-b-20 {
    margin-bottom: 20px;
}

.assess-f {
    font-size: 22px;
    margin-bottom: 14px;
}

.text-sm.dash-f {
    font-size: 1rem;
}

.strat-red {
    background: #013dd2;
    color: #fff;
}

.viewattempt {
    font-size: 13px;
    background: #e5edff;
    padding: 8px;
    border-radius: 6px;
    color: #000;
    border: 1px solid #a9c0f2;
}

.dash-he {
    top: 50%;
    width: 100%;
    vertical-align: middle;
    position: relative;
    height: 88vh;
    padding-left: 15px;
    padding-top: 9px;
}

.border-b {
    border-bottom-width: 1px;
    font-size: 16px;
    color: #000;
}

.space-f-s .hover\:bg-gray-100:hover {
    background: #013dd2;
    color: #fff;
}

.space-f-s .hover\:bg-gray-100:hover .text-gray-500 {
    color: #fff;
}

.space-f-s .active {
    background: #013dd2;
    color: #fff;
}

.space-f-s .active .text-gray-500 {
    color: #fff;
}

.width-20 {
    width: 23%;
}

.textxs-xs {
    font-size: 17px;
    line-height: 24px;
}

.all-que {
    list-style: decimal;
    padding-left: 18px;
    line-height: 32px;
}

.width-3 {
    width: 3%;
}

.font-b {
    font-weight: bold;
}

.mar-bot-top {
    margin-top: 39px;
    margin-bottom: 30px;
}

.w-full-25 {
    width: 25%;
}

.orderRules {
    list-style: decimal;
    padding-left: 42px;
    font-weight: 500;
    margin-top: 20px;
}

.orderinside {
    list-style: decimal;
    padding-left: 42px;
    font-weight: 500;
    margin-top: 10px;
    line-height: 31px;
}

.input-m {
    padding-left: 26px;
    margin-top: 28px;
}

.hindi-m {
    margin-top: 20px;
    display: block;
}

.hindi-opt {
    display: block;
}

.option-m {
    margin-top: 10px;
}

.exam-right {
    width: 350px;
    height: 500px;
    position: fixed;
    right: 0;
    top: 0;
    padding: 10px;
    padding-top: 0px;
}

.time-left {
    background: #000;
    padding: 8px 4px;
    border-radius: 5px;
    margin-top: 10px;
    color: #fff;
    text-align: center;
    font-size: 14px;
}

.assess-de {
    margin-top: 10px;
    background: #013dd21c;
    position: relative;
}

.assess-de p {
    background: #013dd2;
    padding: 10px;
    font-size: 14px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-block-start: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #fff;
}

.assess-list {
    border: 1px solid #e2e2e2;
    border-top: transparent;
    padding: 10px;
    padding-right: 0px;
    margin-top: 0px;
    margin-block-start: 0px;
}

.assess-list li {
    line-height: 23px;
    list-style: none;
}

.assess-span {
    margin-bottom: 6px;
    display: block;
}

.span-float {
    float: left;
    margin-right: 6px;
    margin-top: 2px;
}

.attem-list {
    border: 1px solid #e2e2e2;
    border-top: transparent;
    padding: 10px;
    padding-right: 0px;
    margin-top: 0px;
    margin-block-start: 0px;
    height: 267px;
    overflow: auto;
}

.attem-list li {
    line-height: 23px;
    background: #000000d1;
    width: 35px;
    list-style: none;
    height: 35px;
    border-radius: 50%;
    color: #fff;
    font-size: 17px;
    padding-top: 5px;
    display: inline-block;
    text-align: center;
    margin-bottom: 6px;
    margin: 2px;
}

.footerbottom {
    background: #013dd2;
    height: 62px;
    position: fixed;
    width: 100%;
    bottom: 0px;
    padding-top: 12px;
    padding-left: 10px;
}

.assess-bott {
    background: #a6bef5;
    height: 50px;
    position: relative;
    width: 100%;
    bottom: 0px;
    padding: 5px;
    padding-top: 0px;
}

.rev-com {
    display: inline-block;
    font-size: 8px;
    margin-right: 8px;
}

.not-vis {
    margin-right: 23px;
}

.common-cls {
    width: 11px;
    height: 11px;
    background: #000;
    display: inline-block;
    margin-right: 6px;
}

.text-white.rev-lat {
    background: #fff;
    color: #013dd2;
    border-radius: 2px;
}

.bg-yellow {
    background: #ffd800;
}

.bg-red {
    background: #d03800;
}

.bg-black {
    background: #212121;
}

.bg-blue {
    background: #007bff;
}

.bg-green {
    background: #267b02;
}

.width-72 {
    width: 72%;
    float: left;
}

.width-26 {
    width: 26%;
    float: right;
}

.live-steam {}

.h-500 {
    height: 500px;
}

.exam-left {
    margin-right: 360px;
    padding: 10px;
    padding-top: 0px;
}

.w-full-50 {
    width: 50%;
}

.w-left-70 {
    width: 70%;
    float: left;
}

.w-left-300 {
    width: 300px;
    float: left;
}

.w-full-80 {
    float: right;
    width: 80%;
}

.margin-t-17 {
    margin-top: 17px;
}

.asse-right {
    width: 680px;
}

.assess-w-300 {
    width: 330px;
    right: 0px;
    float: right;
}

.margin-lt-35 {
    margin-left: 27px;
    margin-top: 26px;
}

.margin-t-48 {
    margin-top: 48px;
}

.can-he {
    border: 1px solid #e2e2e2;
    border-top: transparent;
}

.session {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background: #ececec;
    padding: 2px;
    font-size: 14px;
    padding-left: 10px;
}

.can-250 {
    height: 250px;
}

.savne {
    float: left;
}

.all-the {
    float: right;
    margin-right: 50px;
    margin-top: 8px;
    color: #fff;
}

.width-23 {
    width: 23%;
    float: left;
    margin-right: 15px;
}

.bg-white.bott-p0 {
    padding-bottom: 0px;
}

.bg-white.bg-info {
    background: #17a2b8;
}

.more-info {
    width: 100%;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding: 4px;
    color: #fff;
}

.bg-info-dark {
    background: rgba(0, 0, 0, .1);
}

.more-info i {
    font-size: 13px;
}

.today-a {
    padding-left: 15px;
    padding-top: 26px;
    color: #fff;
    padding-bottom: 10px;
}

.today-a .count-t {
    margin-bottom: 16px;
}

.bg-white.bg-warning {
    background: #ffc107;
}

.bg-warning-dark {
    background-color: rgba(0, 0, 0, .1);
}

.bg-white.bg-success {
    background: #28a745;
}

.bg-white.bg-danger {
    background: #dc3545;
}

.being-f li {
    margin-bottom: 20px;
    font-size: 16px;
}

.logoskill {
    width: auto
}

.rs-container {
    display: inline-grid;
    width: 5% !important;
}

.rs-play{
    background: transparent !important;
}

video::-webkit-media-controls {
    display: none;
}

video::-webkit-media-controls-enclosure {
    display: none !important;
}

video::-webkit-media-controls-panel {
    display: none !important;
}